import tingle from "tingle.js";

$().ready(function(){
  
  // Make hover behavior switch to tap/clcik on touch screens
  // --------------------------------------------------------
  // FUTURE:GZ: això ho necessitem?
	// if ($('html').hasClass('touch')) {
  //   var $allTargets = $(".touch-hover-target");
  //
  //   // Hide all targets
  //   $allTargets.hide();
  //
  //   // Handle touch event
	// 	$('.touch-hover-trigger').bind('touchstart', function(e) {
  //     var $trigger = $(this);
  //     var $target = $trigger.find(".touch-hover-target");
	// 		$target.toggle();
	// 		e.stopPropagation();
	// 	});
  //
  //   // Hide when clicking elsewhere
	// 	$(document).bind('touchstart', function(e) {
	// 		$allTargets.hide();
	// 	});
  //
	// 	// Stop events when clicking in target
  //   $('.touch-hover-target').bind('touchstart', function(e){
	// 		e.stopPropagation();
	// 	});
  //
  //   // Hide when clicking links in target
	// 	$('.touch-hover-target a').click(function () {
  //     $(this).closest('.touch-hover-target').hide();
	// 	});
  //
	// }
  
  
  
  // Open external links in new windows
  // ----------------------------------
  $('a[rel*="external"],a[rel*="new-window"]').on('click', function(){ this.target='_blank'; });




  // Finestres modals
  // ----------------
  var modalDefault = new tingle.modal();
  var modalBig = new tingle.modal({
    cssClass: ['modal-is-big'],
  });
  $('.modal').each(function(){
    var $this = $(this);
    var src = $($this.attr('href')).html();
    $this.on('click', function(){
      modalDefault.setContent(src);
      modalDefault.open();
    });
  });
  $('.modal-big').each(function(){
    var $this = $(this);
    var src = $($this.attr('href')).html();
    $this.on('click', function(){
      modalBig.setContent(src);
      modalBig.open();
    });
  });

});
