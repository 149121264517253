$().ready(function () {
    var cookieconsent = initCookieConsent();
    cookieconsent.run({
        autorun: true,
        current_lang: $("html").attr('lang'),
        autoclear_cookies: true,
        page_scripts: true,


        languages: {
            ca: {
                consent_modal: {
                    title: 'Utilitzem el teu navegador per guardar-hi identificadors',
                    description: 'Aquesta pàgina web guarda identificadors al teu navegador que ens són essencials per al seu funcionament. També s’hi guarden identificadors que ens permetran entendre com l’utilitzes per poder-ne millorar així el funcionament. Els identificadors de navegació només els guardarem si ens ho permets.',
                    primary_btn: {
                        text: 'Accepta',
                        role: 'accept_selected'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Preferències',
                        role: 'settings'                // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Preferències',
                    save_settings_btn: 'Guardar preferències',
                    accept_all_btn: 'Accepta-ho tot',
                    reject_all_btn: 'Rebutja-ho tot',       // optional, [v.2.5.0 +]
                    cookie_table_headers: [
                        { col1: 'Nom' },
                        { col2: 'Domini' },
                        { col3: 'Caducitat' },
                        { col4: 'Descripció' }
                    ],
                    blocks: [
                        {
                            title: 'Què guardem.',
                            description: 'Guardem dos tipus d’indentificadors: uns, que ens permeten saber qui ets i què estàs fent i que són essencials, i uns altres que ens permeten saber com et bellugues per la web i entendre com la utilitzes, i que ens permetran millorar-ne el funcionament. Pots activar/desactivar els de navegació.'
                        }, {
                            title: 'Necessari',
                            description: 'Aquests identificadors fan que la web funcioni tal com esperem que ho faci. Ens són imprescindibles.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true
                            },
                            cookie_table: [
                                {
                                    col1: '_mainapp_session',
                                    col2: 'baqueira.es',
                                    col3: 'Sessió',
                                    col4: 'Gestiona la navegació i permet l\'accés als entorns privats amb seguretat',
                                },
                                {
                                    col1: 'cc_cookie',
                                    col2: 'baqueira.es',
                                    col3: '6 mesos',
                                    col4: 'Permet recordar les teves preferències d\'identificadors',
                                },
                                {
                                    col1: 'lang',
                                    col2: 'baqueira.es',
                                    col3: '1 any',
                                    col4: 'Permet recordar les teves preferències d\'idioma',
                                }
                            ]
                        }, {
                            title: 'Navegació',
                            description: 'Aquests identificadors ens permeten saber com s\'interactua amb la web i, amb aquesta informació, poder-ne millorar el funcionament. Ho fem amb Google Analytics. Pots consultar la seva web per saber amb més detalls quins identificadors utilitza.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false
                            },
                        }
                    ]
                }
            },
            en: {
                consent_modal: {
                    title: 'We use your browser to store identifiers',
                    description: 'This website stores identifiers in your browser that are essential for us to operate this website, and we also store identifiers that enable us to understand how you use it so that we can improve how it works. We will only store browser identifiers if you allow us to do so.',
                    primary_btn: {
                        text: 'Accept',
                        role: 'accept_selected'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Preferences',
                        role: 'settings'                // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Preferences',
                    save_settings_btn: 'Save preferences',
                    accept_all_btn: 'Accept all',
                    reject_all_btn: 'Decline all',       // optional, [v.2.5.0 +]
                    cookie_table_headers: [
                        { col1: 'Name' },
                        { col2: 'Domain' },
                        { col3: 'Expiry date' },
                        { col4: 'Description' }
                    ],
                    blocks: [
                        {
                            title: 'What we store.',
                            description: 'We store two types of identifiers: some that allow us to know who you are and what you are doing, which are essential, and others that allow us to know how you move around the site, and understand how you use it, which enable us to improve the way it works. You can enable/disable navigation identifiers.'
                        }, {
                            title: 'Necessary',
                            description: 'These identifiers make the website work as we expect it to, and are essential.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true
                            },
                            cookie_table: [
                                {
                                    col1: '_mainapp_session',
                                    col2: 'baqueira.es',
                                    col3: 'Session',
                                    col4: 'Manages browsing and allows secure access to private environments',
                                },
                                {
                                    col1: 'cc_cookie',
                                    col2: 'baqueira.es',
                                    col3: '6 months',
                                    col4: 'Allows us to remember your identifier preferences',
                                },
                                {
                                    col1: 'lang',
                                    col2: 'baqueira.es',
                                    col3: '1 year',
                                    col4: 'Allows your language preferences to be remembered',
                                }
                            ]
                        }, {
                            title: 'Browsing',
                            description: 'These identifiers allow us to know how you interact with the website. By analysing how you use it, we can improve its performance. We do this using Google Analytics. You can consult its website to find out which identifiers it uses in more detail.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false
                            },
                        }
                    ]
                }
            },
            es: {
                consent_modal: {
                    title: 'Utilizamos tu navegador para guardar identificadores',
                    description: 'Esta página web guarda identificadores en tu navegador que son esenciales para su funcionamiento. Además, también guardamos identificadores que nos permitirán entender cómo la utilizas y poder mejorar así su funcionamiento. Los identificadores de navegación solo los guardaremos si nos lo permites.',
                    primary_btn: {
                        text: 'Aceptar',
                        role: 'accept_selected'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Preferencias',
                        role: 'settings'                // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Preferencias',
                    save_settings_btn: 'Guardar preferencias',
                    accept_all_btn: 'Aceptar todo',
                    reject_all_btn: 'Rechazar todo',       // optional, [v.2.5.0 +]
                    cookie_table_headers: [
                        { col1: 'Nombre' },
                        { col2: 'Dominio' },
                        { col3: 'Caducidad' },
                        { col4: 'Descripción' }
                    ],
                    blocks: [
                        {
                            title: '¿Qué guardamos?',
                            description: 'Guardamos dos tipos de identificadores, unos que nos permiten saber quién eres y qué estás haciendo –estos son esenciales-, y otros que nos permiten saber cómo te mueves por la web y entender cómo la utilizas, lo que no permitirá mejorar su funcionamiento. Puedes activar/desactivar los identificadores de navegación.'
                        }, {
                            title: 'Necesario',
                            description: 'Estos identificadores hacen que la web funcione tal como esperamos que lo haga. Son imprescindibles.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true
                            },
                            cookie_table: [
                                {
                                    col1: '_mainapp_session',
                                    col2: 'baqueira.es',
                                    col3: 'Sesión',
                                    col4: 'Gestiona la navegación y permite el acceso a los entornos privados con seguridad.',
                                },
                                {
                                    col1: 'cc_cookie',
                                    col2: 'baqueira.es',
                                    col3: '6 meses',
                                    col4: 'Permite recordar tus preferencias de identificadores',
                                },
                                {
                                    col1: 'lang',
                                    col2: 'baqueira.es',
                                    col3: '1 año',
                                    col4: 'Permite recordar tus preferencias de idioma',
                                }
                            ]
                        }, {
                            title: 'Navegación',
                            description: 'Estos identificadores nos permiten saber cómo se interactúa con la web. Al analizar cómo se utiliza, podremos mejorar su funcionamiento. Lo hacemos con Google Analytics. Puedes consultar su web para saber con más detalles qué identificadores utiliza.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false
                            },
                        }
                    ]
                }
            },
            fr: {
                consent_modal: {
                    title: 'Nous utilisons votre navigateur pour y sauvegarder des cookies',
                    description: 'Ce site Web installe dans votre navigateur des cookies essentiels à son fonctionnement. Nous y installons également des cookies nous permettant de comprendre comment vous l\'utilisez afin de pouvoir en améliorer le fonctionnement. Les cookies de navigation ne seront enregistrés que si vous l’autorisez.',
                    primary_btn: {
                        text: 'Accepter',
                        role: 'accept_selected'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Préférences',
                        role: 'settings'                // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Préférences',
                    save_settings_btn: 'Sauvegarder les préférences',
                    accept_all_btn: 'Tout accepter',
                    reject_all_btn: 'Tout rejeter',       // optional, [v.2.5.0 +]
                    cookie_table_headers: [
                        { col1: 'Nom' },
                        { col2: 'Domaine' },
                        { col3: 'Échéance' },
                        { col4: 'Description' }
                    ],
                    blocks: [
                        {
                            title: 'Que gardons-nous ?',
                            description: 'Nous gardons deux types de cookies : les premiers nous permettent de savoir qui vous êtes et ce que vous faites, ils sont indispensables ; les autres nous permettent de comprendre comment vous vous déplacez sur le Web et comment vous l\'utilisez, dans le but de pouvoir en améliorer le fonctionnement. Vous pouvez activer/désactiver ceux de navigation'
                        }, {
                            title: 'Nécessaires',
                            description: 'Ces cookies sont indispensables au bon fonctionnement du site Web.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true
                            },
                            cookie_table: [
                                {
                                    col1: '_mainapp_session',
                                    col2: 'baqueira.es',
                                    col3: 'Session',
                                    col4: 'Gère la navigation et permet l\'accès aux environnements privés en toute sécurité'
                                },
                                {
                                    col1: 'cc_cookie',
                                    col2: 'baqueira.es',
                                    col3: '6 mois',
                                    col4: 'Permet de mémoriser vos préférences de cookies ',
                                },
                                {
                                    col1: 'lang',
                                    col2: 'baqueira.es',
                                    col3: '1 an',
                                    col4: 'Permet de mémoriser vos préférences de langue',
                                }
                            ]
                        }, {
                            title: 'Navigation',
                            description: 'Ces cookies nous permettent de mieux connaître les interactions le Web en analysant son utilisation pour pouvoir en améliorer le fonctionnement. Nous utilisons pour ce faire Google Analytics. Vous pouvez consulter leur site Web pour en savoir plus sur les cookies utilisés.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false
                            },
                        }
                    ]
                }
            },
            oc: {
                consent_modal: {
                    title: 'Utilizam eth vòste navegador entà sauvar-i identificadors',
                    description: 'Aguesta pagina web sauve identificadors en vòste navegador que son essenciaus entath sòn foncionament, ath delà, tanben i sauvam identificadors que mos permeteràn enténer coma l’utilizatz entà atau poder-ne mielhorar eth foncionament. Es identificadors de navegacion sonque les sauvaram se mos ac permetetz.',
                    primary_btn: {
                        text: 'Acceptar',
                        role: 'accept_selected'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Preferéncies',
                        role: 'settings'                // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Preferéncies',
                    save_settings_btn: 'Sauvar preferéncies',
                    accept_all_btn: 'Acceptà’c tot',
                    reject_all_btn: 'Refusà’c tot',       // optional, [v.2.5.0 +]
                    cookie_table_headers: [
                        { col1: 'Nòm' },
                        { col2: 'Domini' },
                        { col3: 'Caducitat' },
                        { col4: 'Descripcion' }
                    ],
                    blocks: [
                        {
                            title: 'Qué sauvam.',
                            description: 'Sauvam dus tipes d’indentificadors, uns que mos permeten saber qui ètz e qué hètz, son essenciaus, e uns auti que mos permeten saber coma vos botjatz laguens dera web, enténer coma l’utilizatz e que mos permeteràn mielhorar-ne eth foncionament. Podetz activar/desactivar es de navegacion.'
                        }, {
                            title: 'De besonh',
                            description: 'Aguesti identificadors hèn qu’era web foncione talaments coma demoram qu’ac hèsque. Son indispensables.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true
                            },
                            cookie_table: [
                                {
                                    col1: '_mainapp_session',
                                    col2: 'baqueira.es',
                                    col3: 'Session',
                                    col4: 'Gestione era navegacion e permet er accès as entorns privats tamb seguretat'
                                },
                                {
                                    col1: 'cc_cookie',
                                    col2: 'baqueira.es',
                                    col3: '6 mesi',
                                    col4: 'Permet rebrembar es vòstes preferéncies d\'identificadors',
                                },
                                {
                                    col1: 'lang',
                                    col2: 'baqueira.es',
                                    col3: '1 an',
                                    col4: 'Permet rebrembar es vòstes preferéncies d\'idiòma',
                                }
                            ]
                        }, {
                            title: 'Navegacion',
                            description: 'Aguesti identificadors mos permeten saber coma s\'interactue tamb era web, en tot analisar com s\'utilize poiram mielhorar-ne eth foncionament. Ac hèm tamb Google Analytics. Podetz consultar era sua web entà saber tamb mès detalhs quini identificadors ten.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false
                            },
                        }
                    ]
                }
            },
            pt: {
                consent_modal: {
                    title: 'Instalação de cookies no seu navegador',
                    description: 'Este sítio na Internet instala cookies no seu navegador. Entre estes, encontram-se cookies essenciais para o correto funcionamento do sítio na Internet, e cookies que nos permitem entender como navega pelas suas páginas e assim melhorar o seu funcionamento. Os cookies de navegação só são instalados se contarmos com a sua autorização.',
                    primary_btn: {
                        text: 'Aceitar',
                        role: 'accept_selected'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Preferências',
                        role: 'settings'                // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Preferências',
                    save_settings_btn: 'Guardar preferências',
                    accept_all_btn: 'Aceitar tudo',
                    reject_all_btn: 'Recusar tudo',       // optional, [v.2.5.0 +]
                    cookie_table_headers: [
                        { col1: 'Nome' },
                        { col2: 'Dominio' },
                        { col3: 'Prazo de validade' },
                        { col4: 'Descrição' }
                    ],
                    blocks: [
                        {
                            title: 'Tipo de cookies instalados',
                            description: 'Instalamos dois tipos de cookies, uns que nos permitem saber quem é e o que está a fazer, e que são essenciais, e outros que nos permitem saber como navega pelo nosso sítio na Internet e como o utiliza, e consequentemente melhorar o seu funcionamento. Pode ativar/desativar os cookies de navegação.'
                        }, {
                            title: 'Necessários',
                            description: 'Estes cookies permitem que o sítio na Internet funcione tal como esperamos que funcione. São imprescindíveis.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true
                            },
                            cookie_table: [
                                {
                                    col1: '_mainapp_session',
                                    col2: 'baqueira.es',
                                    col3: 'De sessão',
                                    col4: 'Gerem a navegação e permitem o acesso seguro às áreas privadas do sítio na Internet.'
                                },
                                {
                                    col1: 'cc_cookie',
                                    col2: 'baqueira.es',
                                    col3: '6 meses',
                                    col4: 'Permite recordar as suas preferências de cookies',
                                },
                                {
                                    col1: 'lang',
                                    col2: 'baqueira.es',
                                    col3: '1 ano',
                                    col4: 'Permite recordar as suas preferências de língua',
                                }
                            ]
                        }, {
                            title: 'Navegação',
                            description: 'Estes cookies permitem-nos saber como os utilizadores interagem com o sítio na Internet, e analisando essas interações podemos melhorar o seu funcionamento. Este objetivo é atingido através da utilização do Google Analytics. Pode consultar o seu sítio na Internet para conhecer mais detalhadamente que cookies utiliza.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false
                            },
                        }
                    ]
                }
            }
        }
    });
});
